import React, { useEffect } from "react";
import { navigate, FormattedMessage } from "gatsby-plugin-intl";
import { parse } from "query-string";
import { useLocation } from "@reach/router";

import * as constants from "../../app.constant";
import deviceStore from "../../store/deviceStore";
import AppLayout from "../../components/shared/AppLayout";
import useIsMount from "../../hooks/use-is-mount";
import failedIcon from "../../assets/images/failed.svg";

const PageActivationUnsuccessful = () => {
    const isMount = useIsMount();
    const location = useLocation();

    const navigateBack = () => {
        navigate(constants.ROUTES.IMEI);
    };

    useEffect(() => {
        if (isMount) {
            const searchParams = parse(location.search);
            if (searchParams?.orderNumberId) {
                deviceStore.updateDeviceOrderNumberFailed(searchParams.orderNumberId);
            }
        }
    }, [isMount, location]);

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} hasFooter={true}>
            <div className="app-page page-activation-unsuccessful">
                <div className="activation-unsuccessful">
                    <div className="container">
                        <div className="activation-unsuccessful__header">
                            <h2 className="activation-unsuccessful__status">
                                <img
                                    className="activation-unsuccessful__status-icon img-fluid"
                                    src={failedIcon}
                                    alt="icon"
                                    width="44"
                                />
                                <span className="activation-unsuccessful__status-text text-bold">
                                    <FormattedMessage id="activation.failed.title" />
                                </span>
                            </h2>
                            <p>
                                <FormattedMessage id="activation.failed.desc" />
                            </p>
                        </div>

                        <p className="btn-container btn-container--center">
                            <button className="btn btn-lg btn-primary" type="button" onClick={navigateBack}>
                                <FormattedMessage id="activation.failed.cta" />
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
};

export default PageActivationUnsuccessful;
